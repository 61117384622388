<template>
    <v-container fluid class="mt-1 mb-8">
        <v-row>
            <v-col cols="12">
                <breadcrumb :breadcumbs="breadcumbs"></breadcrumb>
            </v-col>
        </v-row>
        <v-row v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`">
            <v-col
                class="col-6"
                xs="12" 
                sm="5" 
                md="3"
                v-for="item in items" :key="item.name"
            >
                <v-skeleton-loader
                class="mx-auto"
                type="card, card-text"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col class="col-6" sm="4" md="4" lg="3" v-for="(menu_item, i) in menus" :key="i">
                <card-menu-item :menu_item="menu_item"></card-menu-item>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapState } from 'vuex' 
import {menu} from "@/backend-api/menu/index" 
import Breadcrumb from "@/components/Breadcrumb.vue"
import CardMenuItem from "@/components/CardMenuItem.vue"

export default {
    components: {
      Breadcrumb, CardMenuItem
    },
    data() {
        return {
            loading: false,
            breadcumbs: [
                {
                    text: 'Finance',
                    disabled: false,
                    href: '/admin/Finance',
                },
                {
                    text: 'Ledger',
                    disabled: true,
                }
            ],
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            search: '',
            text_dialog:'',
            dialog: false,
            menus: [],
            items: [{name: 'a'}, {name: 'b'}, {name: 'c'}, {name: 'd'}],
            theme: {
                isDark: false
            },
        }
    },
    computed: {
        cardHeight () {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '250'
                case 'sm': return '260'
                case 'md': return '350'
                case 'lg': return '350'
                case 'xl': return '350'
            }
        },
    },
    async mounted(){
        this.loading = true
        await this.getPullData()
        this.loading = false
    },
    methods: {
        checkColor(count){
            return count ? 'red' : 'green'
        },
        async getPullData(){
            var reqBody = {
                'entity_id': 'GKI',
                'appl_id': this.$route.name,
                'path_header_id': this.$route.params.ledger
            }

            const respData = await menu.menu_item('', reqBody, false, false, false)

            if (respData.status === 200) {
                this.menus = respData.data
            }
        }
    }
}
</script>

<style>
.v-card--reveal {
align-items: center;
bottom: 0;
justify-content: center;
opacity: .9;
position: absolute;
width: 100%;
}

.blue_contact_chart{
    background-color: #2eb3ec;
    color: #2eb3ec;
}

.orange_ledger{
    background-color: #f5aa3b;
    color: #f5aa3b;
}

.red_product_journal{
    background-color: #e95044;
    color: #e95044;
}

.green payment{
background-color: #f0f0f0;
    color: #e95044;

}
.other_payment{
    background-color: #d351e9; 
    color: #d351e9;
}
.kas{
    background-color: #5581ad; 
    color: #5581ad;
}
.realisasi{
    background-color: #0078d4; 
    color: #0078d4;
}
</style>